import {TextProvider} from '../../kit-core/provider/text.provider';
import {esaMukeText} from '../text/esa-muke.de.text';

export class ESAMukeInitializer {

  static initialize() {
    ESAMukeInitializer.registerTextPatterns();
  }

  static registerTextPatterns() {
    TextProvider.registerTextPatterns('esa-muke', esaMukeText);
  }

}
