import { Bin, Emptying, EmptyingData } from '../../models/bin.model';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MukeService } from '../../services/muke.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import moment from 'moment';

export interface PeriodicElement {
  index: number;
  timestamp: Date;
  mapsLink: string;
}

@Component({
  selector: 'esa-bin-details',
  templateUrl: './bin-details.component.html',
  styleUrls: ['./bin-details.component.scss']
})
export class BinDetailsComponent implements OnInit {
  isMobileVersion = false;
  bin: Bin;

  rfid: number;

  dataSource: PeriodicElement[];
  displayedColumns = ['index', 'timestamp', 'google-maps'];

  constructor(
    private route: ActivatedRoute,
    private mukeService: MukeService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      this.isMobileVersion = result.matches;
      this.cdr.detectChanges();
    });

    this.route.data.subscribe((data: { bin: Bin }) => {
      this.bin = data.bin;
      this.mukeService.fetchEmptyings(this.bin.rfid)
        .then((data: EmptyingData[]) => {
          this.bin.emptyings = data.map(e => new Emptying(e));
          this.fillDataInList(this.bin);
          this.cdr.detectChanges();
        });
    });
    /*
    this.route.url.subscribe(url => {
      this.rfid = parseInt(url[1]?.path, 10);
      this.mukeService.fetchBin(this.rfid)
      .then(data => {
        this.bin = new Bin(data);
        this.fillDataInList(this.bin);
        this.cdr.detectChanges();
      });
    });*/
  }

  fillDataInList(bin: Bin) {
    this.dataSource = bin.emptyings?.map((e, i, es) => {
      return <PeriodicElement>{
        index: es.length - i,
        timestamp: e.time_stamp,
        mapsLink: `https://www.google.com/maps/place/ ${e.pos_lat},${e.pos_lon}`
      };
    });
  }
}
