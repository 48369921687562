import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ESAForm, ESAFormElement, ESAFormLayout, ESAFormType, ValueChanged } from '../../models/esa-form.model';

@Component({
  selector: 'esa-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormComponent<P, V> implements OnInit {

  readonly type = ESAFormType;
  readonly layout = ESAFormLayout;

  @Input() model: ESAForm;
  @Input() flexDirection: string;

  @Output() valueChanged: EventEmitter<ValueChanged<P, V>> = new EventEmitter<ValueChanged<P, V>>();

  constructor() {
  }

  ngOnInit(): void {
  }

  emit(element: ESAFormElement<P, V>, value: any): void {
    this.valueChanged.emit(new ValueChanged<P, V>(element, value));
  }

  isInput(type: ESAFormType) {
    return type === this.type.DateInput || type === this.type.PasswordInput || type === this.type.TextInput || type === this.type.EmailInput || type === this.type.NumberInput;
  }

  isLabel(type: ESAFormType) {
    return type === this.type.Label;
  }

  isLink(type: ESAFormType) {
    return type === this.type.Link;
  }

  onClick(model: ESAFormElement): void {
    model.onAction();
  }

}
