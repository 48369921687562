export const esaMukeText = {
  'bins': {
    'type': 'Typ',
    'rfid': 'RFID Nummer',
    'thisYear': 'Entleerungen in diesem Jahr',
    'address': 'Adresse',
    'lastEmptying': 'Letzte Entleerung'
  },
  'bin-details': {
    'index': 'Nr.',
    'emptying': 'Entleerung',
    'google-maps': 'Google maps'
  },
  'menu': {
    'bins': 'Mülltonnen',
    'profile': 'Profil',
    'logout': 'Logout'
  },
  'profile': {
    'adress': 'Adresse',
    'bins': 'Mülltonnen',
    'street': 'Straße'
  },
  'notifications': {
    'some-text': 'sometext'
  },
  'form-buttons': {
    'forgotPassword': 'Passwort vergessen?',
    'resetPassword': 'Passwort zurücksetzen'
  }
};
