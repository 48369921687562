<mat-card class="info-card">
  <img matCardAvatar src="../../../../assets/images/material/delete_outline-black-18dp.svg" class="card-avatar"/>
  <div>
    <span class="header" [kitLocalize]="'esa-muke/bins/type'">...</span><br>
    {{bin?.name}}
  </div>
  <div>
    <span class="header" [kitLocalize]="'esa-muke/bins/rfid'">...</span><br>
    {{rfid}}
  </div>
  <div>
    <span class="header" [kitLocalize]="'esa-muke/bins/address'">...</span><br>
    {{bin?.Adress?.street + " " + bin?.Adress?.house_number + bin?.Adress.remaining_address + " " + bin?.Adress.door}}
  </div>
</mat-card>
