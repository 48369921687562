import { Subscription } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { ShellService } from '../../service/shell.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AuthenticationService } from '../../../kit-authentication/service/authentication.service';
import { Router } from '@angular/router';
import { IMenuItem } from '../../model/menu-item.model';
import { User } from '../../../kit-authentication/entity/user.entity';
import { NotificationService } from '../../../esa-muke/services/notification.service';
import { SwPush } from '@angular/service-worker';

@Component({
  selector: 'esa-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShellComponent implements OnInit, OnDestroy {

  isMobileVersion: boolean = true;
  menuItems: IMenuItem[];
  currenUser: User;
  show: any;

  private _menuItemsSubscription: Subscription;
  private _currentUserSubscription: Subscription;
  private _pushSubscription: PushSubscription;

  constructor(private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private shellService: ShellService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private swPush: SwPush,
    private router: Router) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      this.isMobileVersion = result.matches;
      this.cdr.detectChanges();
    });

    this._menuItemsSubscription = this.shellService.menuItems
      .subscribe((menuItems: IMenuItem[]) => {
        this.menuItems = menuItems;
      });

    this.authenticationService.currentUserChanged
      .subscribe((user: User) => {
        this.currenUser = user;
        this.cdr.detectChanges();
      });

    this._currentUserSubscription = this.authenticationService.currentUserChanged
      .subscribe((user: User) => {
        if (user) {
          this.notificationService.requestPermission();
          this.notificationService.receiveMessage();
          this.show = this.notificationService.currentMessage

          // this.notificationService.register();
          // .then((sub: PushSubscription) => this._pushSubscription = sub);
        }
      }
      );
  }

  toOverview() {
    this.router.navigate(['mein-muke']);
  }

  toProfile() {
    this.router.navigate(['profile']);
  }

  ngOnDestroy(): void {
    this._menuItemsSubscription.unsubscribe();
    if (this._pushSubscription) {
      this._pushSubscription.unsubscribe();
    }
  }

  getName(): string {
    return this.currenUser?.name;
  }

}
