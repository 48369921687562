import moment from 'moment';

export interface EmptyingData {
  id: number;
  time_stamp: Date;
  pos_lat: number;
  pos_lon: number;
}

export class Emptying implements EmptyingData {
  id: number;
  time_stamp: Date;
  pos_lat: number;
  pos_lon: number;
  constructor(data: EmptyingData) {
    this.id = data.id;
    this.time_stamp = new Date(moment(data.time_stamp).toISOString());
    this.pos_lat = data.pos_lat;
    this.pos_lon = data.pos_lon;
  }
}

export interface BinData {
  rfid: string;
  customer_number: string;
  name: string;

  Emptyings?: EmptyingData[];
  bintype_id: string;
  address_id?: number;
  Adress?: AdressData;
}

export class Bin implements BinData {
  rfid: string;
  name: string;
  Emptyings?: EmptyingData[];
  lastEmptying?: Emptying;
  Adress?: Adress;

  customer_number: string;
  bintype_id: string;
  address_id?: number;
  emptyings: Emptying[];

  constructor(data: BinData) {
    this.rfid = data.rfid;
    this.name = data.name;

    this.name = data.name;

    this.emptyings = data.Emptyings?.map(d => new Emptying(d));
    this.Adress = data.Adress && new Adress(data.Adress);
  }
}

export interface Year {
  year: number;
  ammount: number;
}

export interface BetreiberData {
  BetreiberId: number;
  Name: String;
  Mail: String;
  prefix: number;
  OEStatNummer: number;
}

export class Betreiber implements BetreiberData {
  BetreiberId: number;
  Name: String;
  Mail: String;
  prefix: number;
  OEStatNummer: number;

  constructor(data: BetreiberData) {
    this.BetreiberId = data.BetreiberId;
    this.Mail = data.Mail;
    this.Name = data.Name;
    this.OEStatNummer = data.OEStatNummer;
    this.prefix = data.prefix;
  }
}

export interface OperatorData {
  operator_id: string;
  name: string;
  mail: string;
  indicator_notification?: any;
  delete_indicator: string;
  address_id?: any;
  zip_code: string;
  oe_stat_nummer: string;
}

export class Operator {
  operator_id: string;
  name: string;
  mail: string;
  indicator_notification?: any;
  delete_indicator: string;
  address_id?: any;
  zip_code: string;
  oe_stat_nummer: string;

  constructor(data: OperatorData) {
    this.operator_id = data.operator_id;
    this.name = data.name;
    this.mail = data.mail;
    this.indicator_notification = data.indicator_notification;
    this.delete_indicator = data.delete_indicator;
    this.address_id = data.address_id;
    this.zip_code = data.zip_code;
    this.oe_stat_nummer = data.oe_stat_nummer;
  }
}

export interface AdressData {
  address_id: string;
  street: string;
  house_number: string;
  door: string;
  remaining_address: string;
  zip_code: string;
  city: string;
}

export class Adress {
  address_id: string;
  street: string;
  house_number: string;
  door: string;
  remaining_address: string;
  zip_code: string;
  city: string;

  constructor(data: AdressData) {
    this.address_id = data.address_id;
    this.street = data.street;
    this.house_number = data.house_number;
    this.door = data.door;
    this.remaining_address = data.remaining_address;
    this.zip_code = data.zip_code;
    this.city = data.city;
  }
}

export const WEEK = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];
