import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthenticationService } from '../../../kit-authentication/service/authentication.service';
import { User } from '../../../kit-authentication/entity/user.entity';
import { MukeService } from '../../services/muke.service';
import { HttpService } from '../../../kit-core/service/http.service';
import { Bin, BinData } from '../../models/bin.model';

@Component({
  selector: 'esa-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user: User;

  constructor(
    private authenticationService: AuthenticationService,
    private mukeService: MukeService,
    private cdr: ChangeDetectorRef,
    private httpService: HttpService
  ) { }

  ngOnInit(): void {
    this.mukeService.fetchUser(parseInt(this.authenticationService.currentUser.id, 10))
      .then(data => {
        this.user = new User(data);

        this.mukeService.fetchBins(parseInt(this.user.id, 10)).then((bins: BinData[]) => {
          this.user.Bins = bins.map(b => new Bin(b));
          this.cdr.detectChanges();
        });
        this.cdr.detectChanges(); //does not work
      });
  }
}
