import { Injectable } from '@angular/core';
import { TextService } from './text.service';
import { ApplicationError } from '../model/application-error.model';
import { HttpService } from './http.service';

@Injectable()
export class ErrorService {

  constructor(
    private httpService: HttpService,
    private textService: TextService
  ) { }

  sendError(error: ApplicationError) {
    // TODO: send error to server
    // console.log(error);
  }

  getErrorMessage(error: ApplicationError) {
    const args = {};
    if (error.Arguments) {
      Object.keys(error.Arguments).forEach((key: string) => {
        const argument = error.Arguments[key];
        if (argument instanceof ApplicationError) {
          args[key] = this.getErrorMessage(argument);
        } else {
          args[key] = argument;
        }

      });
    }
    if (error.Code) {
        return this.textService.getText(error.Code, args);
      } else {
        return null;
    }
  }


}
