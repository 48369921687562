export const shellText = {
  'route': {
    'login': 'ESA Cloud',
    'dashboard': 'Dashboard',
    'changeuserpassword': 'Passwort ändern',
    'authentication': {
      'user-list': 'Benutzerliste'
    }
  }
};
