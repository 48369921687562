import { Component, OnInit } from '@angular/core';
import { ESAForm, FormOptions, ESAFormLayout, ESAFormType, ValueChanged } from '../../../esa-elements/models/esa-form.model';
import { CredentialsData } from '../../../kit-authentication/model/credentials.model';
import { ActivatedRouteSnapshot, ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../kit-core/service/http.service';

@Component({
  selector: 'esa-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm: ESAForm;
  private credentials: CredentialsData = <CredentialsData>{
    resetToken: '',
    password1: '',
    password2: ''
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.credentials.resetToken = this.route.snapshot.paramMap.get('token');

    console.log(this.credentials.resetToken);

    this.resetPasswordForm = new ESAForm(<FormOptions>{
      layout: ESAFormLayout.Column,
      elements: [
        <FormOptions>{
          type: ESAFormType.PasswordInput,
          name: 'newPassword',
          label: 'Neues Passwort',
          onAction: () => {
            this.changePassword();
          }
        },
        <FormOptions>{
          type: ESAFormType.PasswordInput,
          name: 'newPasswordConfirm',
          label: 'Neues Passwort bestätigen',
          onAction: () => {
            this.changePassword();
          }
        },
        <FormOptions>{
          type: ESAFormType.Button,
          label: 'Bestätigen',
          onAction: () => {
            this.changePassword();
          }
        }
      ]
    });
  }

  changePassword() {
    if (this.credentials.password1 === this.credentials.password2) {
      this.httpService.post(`api/users/password`, this.credentials)
      .then(data => {
        if (data < 0) {
          alert('Die Eingaben waren falsch');
        } else {
          alert('Passwort geändert');
          this.router.navigate(['login']);
        }
      })
      .catch(e => alert('Es ist ein Fehler am Server aufgetreten'));
    } else {
      alert('Die Passwörter stimmen nicht überein');
    }
  }

  valueChanged(event: ValueChanged) {
    if (event.element.name == 'newPassword') {
      this.credentials.password1 = <string>event.value;
    } else if (event.element.name == 'newPasswordConfirm') {
      this.credentials.password2 = <string>event.value;
    }
  }
}
