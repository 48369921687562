import {TextProvider} from '../../kit-core/provider/text.provider';
import {authenticationText} from '../text/authentication.de.text';

export class AuthenticationInitializer {

  static initialize() {
    AuthenticationInitializer.registerTextPatterns();
  }

  static registerTextPatterns() {
    TextProvider.registerTextPatterns('authentication', authenticationText);
  }

}
