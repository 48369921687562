import { Component, OnInit, Input } from '@angular/core';
import { Bin } from '../../models/bin.model';

@Component({
  selector: 'esa-bin-info-card',
  templateUrl: './bin-info-card.component.html',
  styleUrls: ['./bin-info-card.component.scss']
})
export class BinInfoCardComponent implements OnInit {
  isMobileVersion = false;

  @Input() bin: Bin;

  public rfid: string;

  constructor() { }

  ngOnInit(): void {
    const zip = this.bin?.Adress?.zip_code;
    this.rfid = this.bin?.rfid?.toString();
    console.error(this.rfid);
    if (this.rfid?.startsWith(zip)) {
      this.rfid = this.rfid.substring(zip.length, this.rfid.length);
    }
  }

}
