import {ErrorComponent} from './esa-muke/components/error/error.component';
import {ESAMukeModule} from './esa-muke/esa-muke.module';
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {AppComponent} from './app.component';

import {CoreModule} from './kit-core/core.module';
import {PushModule} from './kit-push/push.module';
import {AuthenticationModule} from './kit-authentication/authentication.module';
import {TextService} from './kit-core/service/text.service';
import {AuthenticationInterceptor} from './kit-authentication/interceptor/authentication.interceptor';
import {ESAShellModule} from './esa-shell/esa-shell.module';
import {GlobalErrorHandler} from './kit-core/service/global-error-handler.service';
import {TextProvider} from './kit-core/provider/text.provider';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {shellText} from './text/app.de.text';
import {BinListComponent} from './esa-muke/components/bin-list/bin-list.component';
import {AuthenticationGuard} from './kit-authentication/guard/authentication.guard';

import {FormsModule} from '@angular/forms';
import {ProfileComponent} from './esa-muke/components/profile/profile.component';
import {BinDetailsComponent} from './esa-muke/components/bin-details/bin-details.component';
import {BinResolver} from './esa-muke/services/bin.resolve-service';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {ForgotPasswordComponent} from './esa-muke/components/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './esa-muke/components/reset-password/reset-password.component';
import {CreateUserComponent} from './esa-muke/components/create-user/create-user.component';
import {AngularFireModule} from '@angular/fire';
import {LOCALE_ID} from '@angular/core';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

const appRoutes: Routes = [
  {path: '', redirectTo: '/mein-muke', pathMatch: 'full'},
  {path: 'mein-muke', component: BinListComponent, canActivate: [AuthenticationGuard]},
  {path: 'bins', component: BinListComponent, canActivate: [AuthenticationGuard]},
  {
    path: 'bin/:id',
    component: BinDetailsComponent,
    resolve: {
      bin: BinResolver
    },
    canActivate: [AuthenticationGuard]
  },
  {path: 'profile', component: ProfileComponent, canActivate: [AuthenticationGuard]},
  {path: 'error', component: ErrorComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},
  {path: 'reset-password/:token', component: ResetPasswordComponent},
  {path: 'create-user', component: CreateUserComponent},
  {path: '**', redirectTo: 'error'}
];
registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes
      // { enableTracing: true } // <-- debugging purposes only
    ),
    AngularFireModule.initializeApp(environment.firebase),
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    CoreModule,
    ESAShellModule,
    BrowserAnimationsModule,
    BrowserModule,
    PushModule,
    AuthenticationModule,
    FormsModule,
    ESAMukeModule
  ],
  declarations: [
    AppComponent,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de'},
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
    {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(textService: TextService) {
    TextProvider.registerTextPatterns('shell', shellText);
    textService.initialize(TextProvider.getTextPatters());
  }
}
