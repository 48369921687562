import { Component, OnInit } from '@angular/core';
import { ESAForm, FormOptions, ESAFormLayout, ESAFormType, ValueChanged } from '../../../esa-elements/models/esa-form.model';
import { CredentialsData } from '../../../kit-authentication/model/credentials.model';
import { HttpService } from '../../../kit-core/service/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'esa-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  createUserForm: ESAForm;
  private credentials: CredentialsData = <CredentialsData>{
    EMail: ''
  };

  constructor(
    private httpService: HttpService,
    private router: Router
  ) { }

  ngOnInit() {
    this.createUserForm = new ESAForm(<FormOptions>{
      layout: ESAFormLayout.Column,
      elements: [
        <FormOptions>{
          type: ESAFormType.NumberInput,
          name: 'zipCode',
          label: 'Postleitzahl',
          onAction: () => {
            this.createUser();
          }
        },
        <FormOptions>{
          type: ESAFormType.NumberInput,
          name: 'costumerNumber',
          label: 'Kundennummer',
          onAction: () => {
            this.createUser();
          }
        },
        <FormOptions>{
          type: ESAFormType.TextInput,
          name: 'lastName',
          label: 'Nachname',
          onAction: () => {
            this.createUser();
          }
        },
        <FormOptions>{
          type: ESAFormType.EmailInput,
          name: 'email',
          label: 'E-Mail',
          onAction: () => {
            this.createUser();
          }
        },
        <FormOptions>{
          type: ESAFormType.Button,
          label: 'Zugang anfordern',
          onAction: () => {
            this.createUser();
          }
        }
      ]
    });
  }

  createUser() {
    this.httpService.post(`api/users/create`, this.credentials)
    .then(data => {
      console.log(data);
      if (data == -2) {
        alert('Diese E-Mail wird schon für 3 Kundennummern verwendet');
      } else if (data < 0) {
        alert('Ihre Eingaben stimmen nicht überein');
      } else {
        alert('Ihnen wurde eine E-Mail zum Setzen Ihres Passworts gesendet');
        this.router.navigate(['login']);
      }
    })
    .catch(e => alert('Es ist ein Fehler am Server aufgetreten'));
  }

  valueChanged(event: ValueChanged) {
    switch (event.element.name) {
      case 'zipCode':
        this.credentials.zipCode = <string>event.value;
        break;
      case 'costumerNumber':
        this.credentials.clientNumber = <string>event.value;
        break;
      case 'lastName':
        this.credentials.LastName = <string>event.value;
        break;
      case 'email':
        this.credentials.EMail = <string>event.value;
        break;
    }
  }
}
