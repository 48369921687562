export const environment = {
  production: true,
  firebase: {
    appId: "1:1097202436873:web:eb9f1c6fdcf816ec54e1d8",
    apiKey: 'AIzaSyALBXCmdWe-77QvkCWpFb0tgUB7_4dWje4',
    authDomain: 'mueke-284108.firebaseapp.com',
    databaseURL: 'https://mueke-284108.firebaseio.com',
    projectId: 'mueke-284108',
    storageBucket: 'mueke-284108.appspot.com',
    messagingSenderId: '1097202436873'
  }
};
