import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../kit-authentication/entity/user.entity';

@Component({
  selector: 'esa-adresse',
  templateUrl: './adresse.component.html',
  styleUrls: ['./adresse.component.scss']
})
export class AdresseComponent implements OnInit {

  @Input() user: User;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  goToBins() {
    this.router.navigate(['bins']);
  }
}
