import { Bin, Emptying, EmptyingData } from '../../models/bin.model';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MukeService } from '../../services/muke.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { TextService } from '../../../kit-core/service/text.service';
import { Layout } from '../../models/layout.model';

@Component({
  selector: 'esa-bin',
  templateUrl: './bin.component.html',
  styleUrls: ['./bin.component.scss']
})
export class BinComponent implements OnInit {
  isMobileVersion = false;
  readonly Layout = Layout;

  @Input() bin: Bin;
  @Input() layout: Layout;

  constructor(private mukeService: MukeService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private textService: TextService) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      this.isMobileVersion = result.matches;
      this.cdr.detectChanges();
    });

    //this.label = this.textService.getPattern('esa-muke/test')

    this.mukeService.fetchLastEmptying(this.bin.rfid)
      .then((data: EmptyingData) => {
        this.bin.lastEmptying = new Emptying(data);
        this.cdr.detectChanges();
      });

    this.mukeService.fetchEmptyings(this.bin.rfid)
      .then((data: EmptyingData[]) => {
        this.bin.emptyings = data.map(d => new Emptying(d));
        this.cdr.detectChanges();
      });
  }

  getEmptyingsThisYear() {
    return this.bin.emptyings?.filter(e => e.time_stamp.getFullYear() === new Date().getFullYear());
  }

  showInfo() {
    this.router.navigate(['bin', this.bin.rfid]);
  }
}
