import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormComponent} from './components/esa-form/form.component';
import {TextInputComponent} from './components/text-input/text-input.component';
import {ButtonComponent} from './components/button/button.component';
import {VirtualizedListComponent} from './components/virtualized-list/virtualized-list.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CheckBoxComponent} from './components/check-box/check-box.component';
import {MatBadgeModule} from '@angular/material/badge';
import { CoreModule } from '../kit-core/core.module';

@NgModule({
  declarations: [
    FormComponent,
    TextInputComponent,
    ButtonComponent,
    VirtualizedListComponent,
    CheckBoxComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatInputModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    ScrollingModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatCardModule
  ],
  exports: [
    FormComponent
  ]
})
export class ESAElementsModule {
}
