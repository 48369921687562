<div class="kit-shell-body">

  <div class="esa-shell-header-container">
    <p>
      <mat-toolbar color="primary">
        <div class="esa-shell-header-logo" (click)="toOverview()">
          <img class="muke-logo" src="../../../../assets/images/esa/muke-logo.svg" alt="">
          <span class="ESA">ESA</span>
        </div>
        <div class="esa-shell-header-spacer"></div>
        <div class="esa-shell-header-menu" [kitVisibleIfLoggedIn]="true">
          <div class="esa-shell-name" (click)="toProfile()"><span>{{getName()}}</span></div>
          <!--
          <button mat-icon-button class="menu" [matMenuTriggerFor]="notifications">
            <mat-icon>notifications_active</mat-icon>
          </button>-->
          <button mat-icon-button class="menu" [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
      </mat-toolbar>
    </p>


    <div class="esa-shell-content">
      <ng-content></ng-content>
    </div>

  </div>
</div>

<mat-menu #notifications="matMenu">

  <button mat-menu-item>
    <mat-icon></mat-icon>
    <span>Entleerung</span>
  </button>
</mat-menu>

<mat-menu #menu="matMenu">

  <button mat-menu-item *ngFor="let menuItem of menuItems" class="nav-button" (click)="menuItem.onAction()">
    <mat-icon>{{menuItem.icon}}</mat-icon>
    <span>{{menuItem.label}}</span>
  </button>
</mat-menu>