<mat-card class="profile-card">
  <mat-card-header>
    <div mat-card-avatar class="profile-header-image"></div>
    <mat-card-title>{{user?.name}}</mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="user?.Adresses && user.Adresses[0]" class="profile-content">
    <p><span [kitLocalize]="'esa-muke/profile/adress'"></span>: {{user.Adresses[0].city}}</p>
    <p><span [kitLocalize]="'esa-muke/profile/bins'"></span>: {{user.Bins?.length}}<!--<button mat-icon-button (click)="goToBins()" class="inline-button">
       <mat-icon class="mat-18 inline-icon">open_in_new</mat-icon>
      </button> --></p>
    <p><span [kitLocalize]="'esa-muke/profile/street'"></span>: {{user.Adresses[0].street}} {{user.Adresses[0].house_number}}{{user.Adresses[0].remaining_address}} {{user.Adresses[0].door}}</p>
  </mat-card-content>
</mat-card>
