import {Directive, ElementRef, Input, OnInit, Renderer2} from "@angular/core";
import {TextService} from "../service/text.service";

@Directive({
  selector: '[kitLocalize]'
})
export class LocalizeDirective implements OnInit {

  private _element: HTMLElement;
  private _pattern: string;
  private _label: string;

  constructor(
    private element: ElementRef,
    private textService: TextService
  ) {
    this._element = element.nativeElement;
  }

  ngOnInit() {
  }

  @Input('kitLocalize')
  set pattern(value: string) {
    this._pattern = value;
    this._label = this.textService.getPattern(this._pattern);
    this.update();
  }

  get label(): string {
    return this._label;
  }

  update(): void {
    this._element.innerHTML = this.label;
  }


}
