<div class="esa-form-container" [id]="model.id" [class.row]="model.layout === layout.Row"
  [class.column]="model.layout === layout.Column">

  <ng-container *ngFor="let element of model.elements">

    <div *ngIf="element.isESAForm">
      <div class="esa-from-group-body">
        <esa-form [model]="element" [flexDirection]="model.layout"></esa-form>
      </div>
    </div>

    <div *ngIf="!element.isESAForm" class="esa-form-control-container">
      <label *ngIf="isLabel(element.type)">{{element.label}}</label>

      <a *ngIf="isLink(element.type)" (click)="onClick(element)">{{element.label}}</a>

      <esa-text-input *ngIf="isInput(element.type)" [model]="element" (valueChange)="emit(element, $event)">
      </esa-text-input>

      <esa-button *ngIf="element.type === type.Button" [model]="element" (valueChange)="emit(element, $event)">
      </esa-button>

      <esa-virtualized-list *ngIf="element.type === type.VirtualizedList" [model]="element"
        (valueChange)="emit(element, $event)"></esa-virtualized-list>

      <esa-check-box *ngIf="element.type === type.CheckBox" [model]="element" (valueChange)="emit(element, $event)">
      </esa-check-box>
    </div>

  </ng-container>

</div>