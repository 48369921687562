<div class="esa-bin-container">
  <mat-card *ngIf="layout === Layout.Grid" class="esa-bin-card" (click)="showInfo()" [ngClass]="isMobileVersion?'mobile':'desktop'">
    <div class="esa-bin-info">
      <span class="header" [kitLocalize]="'esa-muke/bins/address'"></span><br>
      {{bin?.Adress.street + " " + bin?.Adress.house_number + bin?.Adress.remaining_address + " " + bin?.Adress.door}}
    </div>
    <div class="esa-icon">
      <img class="item esa-icon" matCardAvatar src="../../../../assets/images/material/delete_outline-black-18dp.svg"/>
    </div>
    <div class="esa-bin-info esa-bin-type">
      {{bin?.name}}
    </div>
    <div class="esa-bin-info esa-bin-emptying">
      <span class="header" [kitLocalize]="'esa-muke/bins/lastEmptying'">...</span><br>
      {{bin?.lastEmptying?.time_stamp | date:'dd.MM.yyyy \'um\' HH:mm'}}
    </div>
    <div class="esa-bin-info">
      <span class="header" [kitLocalize]="'esa-muke/bins/thisYear'">...</span><br>
      {{getEmptyingsThisYear()?.length}}
    </div>
  </mat-card>
  <div class="line" matRipple *ngIf="layout === Layout.List" (click)="showInfo()" [ngClass]="isMobileVersion?'mobile':'desktop'">
    <div class="esa-icon">
      <img matCardAvatar src="../../../../assets/images/material/delete_outline-black-18dp.svg"/>
    </div>
    <div class="body">
      <div class="item esa-bin-type list">
        <span class="header" [kitLocalize]="'esa-muke/bins/type'">...</span><br>
        {{bin.name}}
      </div>
      <div class="item esa-bin-address list">
        <span class="header" [kitLocalize]="'esa-muke/bins/address'">...</span><br>
        {{bin?.Adress.street + " " + bin?.Adress.house_number + bin?.Adress.remaining_address + " " + bin?.Adress.door}}
      </div>
      <div class="item esa-bin-last-emptying list">
        <span class="header" [kitLocalize]="'esa-muke/bins/lastEmptying'">...</span><br>
        {{bin?.lastEmptying?.time_stamp | date:'dd.MM.yyyy \'um\' HH:mm'}}
      </div>
      <div class="item esa-bin-emptying-count list">
        <span class="header" [kitLocalize]="'esa-muke/bins/thisYear'">...</span><br>
        {{getEmptyingsThisYear()?.length}}
      </div>
    </div>
  </div>
</div>
