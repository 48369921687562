import { Entity, EntityData } from '../../kit-core/model/entity.model';
import { BinData, OperatorData, AdressData, Bin, Adress, Operator } from '../../esa-muke/models/bin.model';

export interface UserData extends EntityData {
  id: string;
  customer_number: string;
  name: string;
  mail?: any;
  indicator_notification?: any;
  delete_indicator?: any;
  operator_id: string;
  Adresses?: AdressData[];
  Operator?: OperatorData;
  Bins?: BinData[];
}

export class User extends Entity implements UserData {
  id: string;
  customer_number: string;
  name: string;
  mail: string;
  indicator_notification?: any;
  delete_indicator?: any;
  operator_id: string;
  Adresses?: Adress[];
  Operator?: Operator;
  Bins?: Bin[];

  constructor(data: UserData) {
    super(data);
    this.id = data.id;
    this.customer_number = data.customer_number;
    this.name = data.name;
    this.mail = data.mail;
    this.indicator_notification = data.indicator_notification;
    this.delete_indicator = data.delete_indicator;
    this.operator_id = data.operator_id;

    this.Adresses = data.Adresses && data.Adresses.map(d => new Adress(d));
    this.Operator = data.Operator && new Operator(data.Operator);
    this.Bins = data.Bins && data.Bins.map(d => new Bin(d));
  }
}
