import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {ESAElementsModule} from '../esa-elements/esa-elements.module';
import {AuthenticationService} from './service/authentication.service';
import {AuthenticationGuard} from './guard/authentication.guard';
import {LoginComponent} from './component/login/login.component';
import {CoreModule} from '../kit-core/core.module';
import {UserService} from './service/user.service';
import {AuthenticationInitializer} from './initializer/authentication.initializer';
import {VisibleIfLoggedIn} from './directive/visible-if-logged-in.directive';

const routes: Routes = [
  {path: 'login/', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [AuthenticationGuard]},
  {path: 'authentication/user-list/', redirectTo: '/authentication/user-list', pathMatch: 'full'},
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CoreModule,
    ESAElementsModule
  ],
  declarations: [
    LoginComponent,
    VisibleIfLoggedIn,
  ],
  providers: [
    AuthenticationService,
    AuthenticationGuard,
    UserService
  ],
  exports: [
    VisibleIfLoggedIn
  ]
})
export class AuthenticationModule {

  constructor() {
    AuthenticationInitializer.initialize();
  }

}
