import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { ESAFormElement, ESAFormType } from '../../models/esa-form.model';
import { TextService } from '../../../kit-core/service/text.service';

@Component({
  selector: 'esa-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class TextInputComponent implements OnInit {
  value: string = '';

  @Input() model: ESAFormElement;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(private textService: TextService) {}

  ngOnInit(): void {
  }

  change(event): void {
    this.valueChange.emit(event.target.value);
  }

  getHtmlType(): string {
    let htmlType: string;
    switch (this.model.type) {
      case ESAFormType.PasswordInput:
        htmlType = 'password';
        break;
      case ESAFormType.EmailInput:
        htmlType = 'email';
        break;
      case ESAFormType.NumberInput:
        htmlType = 'number';
        break;
      default:
        htmlType = 'text';
        break;
    }

    return htmlType;
  }
}
