import { Component, OnInit } from '@angular/core';
import { ESAForm, FormOptions, ESAFormLayout, ESAFormType, ValueChanged } from '../../../esa-elements/models/esa-form.model';
import { CredentialsData } from '../../../kit-authentication/model/credentials.model';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../../../kit-core/service/http.service';

@Component({
  selector: 'esa-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: ESAForm;
  private credentials: CredentialsData = <CredentialsData>{
    EMail: ''
  };

  constructor(
    private httpService: HttpService
  ) { }

  ngOnInit() {
    this.forgotPasswordForm = new ESAForm(<FormOptions>{
      layout: ESAFormLayout.Column,
      elements: [
        <FormOptions>{
          type: ESAFormType.EmailInput,
          name: 'email',
          label: 'E-Mail',
          onAction: () => {
            this.sendEmail();
          }
        },
        <FormOptions>{
          type: ESAFormType.Button,
          label: 'E-Mail senden',
          onAction: () => {
            this.sendEmail();
          }
        }
      ]
    });
  }

  sendEmail() {
    //TODO
    this.httpService.post(`api/users/resetpassword`, this.credentials)
      .then(data => {
        if (data < 0) {
          alert('Falsche Email angegeben');
        } else {
          alert('Email wurde versendet');
        }
      })
      .catch(e => alert('Falsche Email angegeben'));
  }

  valueChanged(event: ValueChanged) {
    if (event.element.name == 'email') {
      this.credentials.EMail = <string>event.value;
    }
  }
}
