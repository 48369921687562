import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { SwPush } from '@angular/service-worker';
import { AuthenticationService } from '../../kit-authentication/service/authentication.service';
import { HttpService } from '../../kit-core/service/http.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private readonly VAPID_PUBLIC_KEY = 'BEi1mptbudk4lJi9zZRj0wB2uSC3xZRoR1DJb1Xw8M9JcCscOMS_-IiSh5QQ9XGubyz7x7ZM-VpCNNfHj4AKQXc';
  currentMessage = new BehaviorSubject(null);

  constructor(
    private firestore: AngularFirestore,
    private authenticationService: AuthenticationService,
    private httpService: HttpService,
    private swPush: SwPush,
    private afAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging
  ) {

    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )

  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.httpService.post('api/notifications/subscribe', { subscriptionToken: token })
          .then(response => {
            console.error(response);
          });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
  
}
