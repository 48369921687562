<button *ngIf="this.bins?.length > 1 && !isMobileVersion"  mat-mini-fab (click)="toggleLayout()" color="primary" class="toggle-button"><mat-icon>{{layout === Layout.Grid ? 'view_list' : 'view_module'}}</mat-icon></button>

<div class="esa-test-container">
  <ng-container *ngIf="garbageBins else loading">
    <div class="bin-cards-container" *ngIf="layout === Layout.Grid" [ngClass]="isMobileVersion?'mobile':'desktop'">
      <ng-container *ngFor="let garbageBin of garbageBins">
        <esa-bin [layout]="layout" [bin]="garbageBin" class="esa-bin-card"></esa-bin>
      </ng-container>
    </div>
    <ng-container *ngIf="layout === Layout.List">
      <mat-card class="list-card" [ngClass]="isMobileVersion?'mobile':'desktop'">
        <mat-list>
          <div mat-subheader>Bins</div>
          <ng-container *ngFor="let garbageBin of garbageBins">
            <esa-bin [layout]="layout" [bin]="garbageBin" class="bin-container"></esa-bin>
            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
      </mat-card>
    </ng-container>
  </ng-container>
</div>

<!-- loading template -->
<ng-template #loading>
  <div class="loading">
    <mat-spinner [diameter]="15"></mat-spinner>
  </div>
</ng-template>
