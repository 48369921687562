export enum ESAFormType {
  Form,
  Label,
  TextInput,
  Button,
  VirtualizedList,
  PasswordInput,
  CheckBox,
  DateInput,
  EmailInput,
  NumberInput,
  Link
}

export enum ESAFormLayout {
  Row,
  Column
}

export interface FormOptions<P = undefined, V = undefined> {
  type: ESAFormType;
  id?: string;
  name?: P;
  label: string;
  attributes?: IESAAttributes;
  value?: V;

  layout?: ESAFormLayout;
  elements: FormOptions<P, V>[];

  onAction?: () => void;
  forgotPassword?: () => void;
}

export interface IESAAttributes {
  type?: string;
  name?: string;
  value?: any;
  id?: string;
}

abstract class IESAForm<P, V> {
  readonly type: ESAFormType;
  readonly id?: string;
  readonly name?: string;
  readonly label?: string;
  readonly value?: V;
  readonly attributes: IESAAttributes;

  protected constructor(protected readonly options: FormOptions) {
    this.options = options;

    this.id = options.id;
    this.type = options.type;
    this.name = options.name;
    this.label = options.label;
    this.value = options.value;
    this.attributes = options.attributes;
  }

  abstract get isESAForm(): boolean;

}

export class ESAFormElement<P = undefined, V = undefined> extends IESAForm<P, V> {

  constructor(options: FormOptions) {
    super(options);
  }

  get isESAForm(): boolean {
    return false;
  }

  get onAction() {
    return this.options.onAction;
  }

  get forgotPassword() {
    return this.options.forgotPassword;
  }

}

export class ESAForm extends IESAForm<undefined, undefined> {

  readonly layout: ESAFormLayout;
  readonly elements: (ESAForm | ESAFormElement)[];

  constructor(options: FormOptions) {
    super(options);

    this.layout = options.layout ?? ESAFormLayout.Column;

    this.elements = options?.elements
      .map((element: FormOptions) => {
        if (element.elements) {
          return new ESAForm(element);
        }
        return new ESAFormElement(element);
      });
  }

  get isESAForm(): boolean {
    return true;
  }

}

export class ValueChanged<P = undefined, V = undefined> {
  constructor(public readonly element: ESAFormElement<P, V>,
    public readonly value: V
  ) {
  }
}

export class Page {
  constructor(readonly name: string,
    readonly url: string,
    readonly notifications?: number
  ) { }
}
