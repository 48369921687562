import { EmptyingData, BinData, Emptying, BetreiberData } from '../models/bin.model';
import { Injectable } from '@angular/core';
import { HttpService } from '../../kit-core/service/http.service';
import { UserData } from '../../kit-authentication/entity/user.entity';
@Injectable({
  providedIn: 'root'
})
export class MukeService {

  constructor(private httpService: HttpService) { }

  fetchUser(id: number): Promise<UserData> {
    return this.httpService.get(`api/users/current`)
      .then(data => data);
  }

  fetchBins(id: number): Promise<BinData[]> {
    return this.httpService.get(`api/bins/list`)
      .then(data => data);
  }

  fetchBin(id: string): Promise<BinData> {
    return this.httpService.get(`api/bins/get/rfid/${id}`)
      .then(data => data);
  }

  fetchEmptyings(RFID: string): Promise<EmptyingData[]> {
    return this.httpService.get(`api/bins/get/rfid/${RFID}/emptyings/list`)
      .then(data => data);
  }

  fetchLastEmptying(RFID: string) {
    return this.httpService.get(`api/bins/get/rfid/${RFID}/emptyings/last`)
      .then(data => data);
  }
}
