import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BinData } from '../models/bin.model';
import { MukeService } from './muke.service';

@Injectable({ providedIn: 'root' })
export class BinResolver implements Resolve<BinData> {
  constructor(private service: MukeService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    return this.service.fetchBin(route.paramMap.get('id'));
  }
}
