import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { ESAElementsModule } from '../esa-elements/esa-elements.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BinListComponent } from './components/bin-list/bin-list.component';
import { BinComponent } from './components/bin/bin.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { AdresseComponent } from './components/adresse/adresse.component';
import { ProfileComponent } from './components/profile/profile.component';
import { UebersichtComponent } from './components/uebersicht/uebersicht.component';
import { MatTableModule } from '@angular/material/table';
import { BinDetailsComponent } from './components/bin-details/bin-details.component';
import { AddKundenNrComponent } from './components/add-kunden-nr/add-kunden-nr.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ESAMukeInitializer } from './initializer/esa-muke.initializer';
import { CoreModule } from '../kit-core/core.module';
import { BinInfoCardComponent } from './components/bin-info-card/bin-info-card.component';
import { MatRippleModule } from '@angular/material/core';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CreateUserComponent } from './components/create-user/create-user.component';


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ESAElementsModule,
    MatCardModule,
    MatListModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatBadgeModule,
    MatButtonModule,
    MatTableModule,
    MatRippleModule
  ],
  declarations: [
    BinListComponent,
    BinComponent,
    UebersichtComponent,
    BinDetailsComponent,
    ProfileComponent,
    AdresseComponent,
    AddKundenNrComponent,
    ChangeEmailComponent,
    BinInfoCardComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    CreateUserComponent
  ]
})
export class ESAMukeModule {

  constructor() {
    ESAMukeInitializer.initialize();
  }

}
