import { Component, OnInit, Input } from '@angular/core';
import { ESAForm, FormOptions, ESAFormLayout, ESAFormType } from '../../../esa-elements/models/esa-form.model';
import { HttpService } from '../../../kit-core/service/http.service';
import { User } from '../../../kit-authentication/entity/user.entity';

@Component({
  selector: 'esa-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {

  @Input() user: User;
  changEmailForm: ESAForm;

  constructor(private httpService: HttpService) { }

  ngOnInit(): void {
    this.changEmailForm = new ESAForm(<FormOptions>{
      layout: ESAFormLayout.Column,
      elements: [
        <FormOptions>{
          type: ESAFormType.Button,
          label: 'Passwort zurücksetzen',
          onAction: () => {
            console.log('Reset');
            this.httpService.post('/api/users/resetpassword', { EMail: this.user.mail })
              .then(() => alert('Ihnen wurde ein Link zum Zurücksetzen des Passworts gesendet.'));
          }
        },
        /*<FormOptions>{
          type: ESAFormType.EmailInput,
          name: 'newMail',
          label: 'neue Email',
          onAction: () => {
            //TODO
          }
        },
        <FormOptions>{
          type: ESAFormType.Button,
          label: 'Email ändern',
          onAction: () => {
          }
        }*/
      ]
    });
  }
}
