import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {HttpService} from './service/http.service';
import {TextService} from './service/text.service';
import {AlertService} from './service/alert.service';
import {ErrorService} from './service/error.service';
import {LocalizeDirective} from "./directives/localize.directive";

@NgModule({
  imports: [
    // RouterModule,
    HttpClientModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  declarations: [
    LocalizeDirective
  ],
  providers: [
    HttpService,
    TextService,
    ErrorService,
    AlertService
  ],
  exports: [
    LocalizeDirective
  ]
})
export class CoreModule {

}
