<div>
  <div class="bin-info" *ngIf="bin" [ngClass]="isMobileVersion?'mobile':'desktop'">
    <esa-bin-info-card [bin]="bin"></esa-bin-info-card>
  </div>
  <div class="emptyings-table" [ngClass]="isMobileVersion?'mobile':'desktop'">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Position Column -->
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef class="index-col header" [kitLocalize]="'esa-muke/bin-details/index'">...</th>
        <td mat-cell *matCellDef="let element" class="index-col">{{element.index}}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="timestamp">
        <th mat-header-cell *matHeaderCellDef class="timestamp-col header" [kitLocalize]="'esa-muke/bin-details/emptying'">...</th>
        <td mat-cell *matCellDef="let element" class="timestamp-col">{{element.timestamp | date:'EEEE, dd.MM.yyyy \'um\' HH:mm'}}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="google-maps">
        <th mat-header-cell *matHeaderCellDef class="google-maps-col header" [kitLocalize]="'esa-muke/bin-details/google-maps'">...</th>
        <td mat-cell *matCellDef="let element" class="google-maps-col"><a href="{{element.mapsLink}}" mat-icon-button
            target="_blank">
            <mat-icon class="mat-18">location_on</mat-icon>
          </a></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
