import {Bin, BinData} from '../../models/bin.model';
import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MukeService} from '../../services/muke.service';
import {Layout} from '../../models/layout.model';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AuthenticationService} from '../../../kit-authentication/service/authentication.service';

@Component({
  templateUrl: './bin-list.component.html',
  styleUrls: ['./bin-list.component.scss'],
})
export class BinListComponent implements OnInit {

  readonly Layout = Layout;
  layoutSetting: Layout;

  bins: Bin[];
  isMobileVersion = false;

  constructor(
    private mukeService: MukeService,
    private cdr: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      this.isMobileVersion = result.matches;
      this.cdr.detectChanges();
    });

    this.mukeService.fetchBins(parseInt(this.authenticationService.currentUser.id, 10))
      .then((data: BinData[]) => {
        this.bins = data.map((d: BinData) => new Bin(d));
        this.cdr.detectChanges();
      });

    this.layoutSetting = parseInt(localStorage.getItem('bins-view-layout'), 10);
    if (!Layout[this.layoutSetting]) {
      this.layoutSetting = Layout.Grid;
    }
    this.cdr.detectChanges();
  }

  get garbageBins(): Bin[] {
    return this.bins;
  }

  toggleLayout() {
    if (this.layoutSetting === Layout.Grid) {
      this.layoutSetting = Layout.List;
    } else {
      this.layoutSetting = Layout.Grid;
    }

    localStorage.setItem('bins-view-layout', `${this.layoutSetting}`);
  }

  get layout() {
    return this.isMobileVersion ? Layout.Grid : this.layoutSetting ?? Layout.Grid;
  }
}
